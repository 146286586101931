import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P22-E1',
    audio: 'img/FriendsPlus/Page22/Audio/audio.e1.p22.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page22/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/tieude.e1.p22.mp3'},
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/3.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/dress.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/socks.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/T-shirt.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/pants.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/7.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/shorts.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/shoes.mp3'},
      ],
    ],
  },
  2: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P22-E2',
    audio: 'img/FriendsPlus/Page22/Audio/audio.e2.p22.mp3',
    video: '',
    songLyric:
    "<p>dress, dress, dress </p>"+
    "<p>socks, socks, socks </p>"+
    "<p>T-shirt, T-shirt, T-shirt </p>"+
    "<p>pants, pants, pants </p>"+
    "<p>shorts, shorts, shorts </p>"+
    "<p>shoes, shoes, shoes </p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page22/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page22/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/tieude.e2.p22.mp3'},
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/3.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/dress.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/socks.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/T-shirt.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/pants.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/7.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/shorts.mp3'},
        { url: 'img/FriendsPlus/Page22/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/shoes.mp3'},
      ],
    ],
  },
  3: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P22-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page22/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E3/2.jpg'},
        { url: 'img/FriendsPlus/Page22/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/pants.mp3'},
        { url: 'img/FriendsPlus/Page22/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/dress.mp3'},
        { url: 'img/FriendsPlus/Page22/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/T-shirt.mp3'},
        { url: 'img/FriendsPlus/Page22/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/10.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/shorts.mp3'},
        { url: 'img/FriendsPlus/Page22/E3/11.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/socks.mp3'},
        { url: 'img/FriendsPlus/Page22/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E3/13.jpg'},
        { url: 'img/FriendsPlus/Page22/E3/14.jpg' , audioUrl: 'img/FriendsPlus/Page22/Audio/shoes.mp3'},
      ],
    ],
  },
}

export default json;