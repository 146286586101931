import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {

  1: { // Exercise num
    unit : 'Culture',
    id : 'SB2-C-P50-E1',
    audio: 'img/FriendsPlus/Page50/Audio/audio.e1.p50.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page50/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/tieude.e1.p50.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Viet Nam.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Korea.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/the U.S.A.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Thailand.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Switzerland.mp3' },
      ],

    ],
  },


  2: { // Exercise num
    unit : 'Culture',
    id : 'SB2-C-P50-E2',
    audio: 'img/FriendsPlus/Page50/Audio/audio.e2.p50.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page50/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page50/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/tieude.e2.p50.mp3' },
        { url: 'img/FriendsPlus/Page50/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page50/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/bé Việt.mp3' },
        { url: 'img/FriendsPlus/Page50/E2/6.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page50/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/bé hàn.mp3' },
        { url: 'img/FriendsPlus/Page50/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/bé Mỹ.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/bé Thụy Sỹ.mp3' },
        { url: 'img/FriendsPlus/Page50/E2/10.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/bé Thái.mp3' },
      ],

    ],
  },

  3: { // Exercise num
    unit : 'Culture',
    id : 'SB2-C-P50-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page50/E3/1.jpg' },
      ],

    ],
  },


}
export default json;
