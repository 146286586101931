import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "SB2-U2-P18-E1",
    audio: "img/FriendsPlus/Page18/E1/Audio/audio.e1.p18.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page18/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/tieude.e1.p18.mp3",
        },
        { url: "img/FriendsPlus/Page18/E1/3.jpg" },
        { url: "img/FriendsPlus/Page18/E1/4.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page18/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/Q.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/queen.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/quiet.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page18/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/R.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/river.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/rainbow.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB2-U2-P18-E2",
    audio: "img/FriendsPlus/Page18/E2/Audio/audio.e2.p18.mp3",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    songLyric:
      "<p>Queen, the queen</p>" +
      "<p>/kw/  /kw/  /kw/</p>" +
      "<p>The queen is quiet.</p>" +
      "<p>/kw/  /kw/  /kw/</p>" +
      "<p>Look at the rainbow</p>" +
      "<p>/r/  /r/  /r/</p>" +
      "<p>By the river</p>" +
      "<p>/r/  /r/  /r/</p>" +
      "<p>Queen, the queen</p>" +
      "<p>/kw/  /kw/  /kw/</p>" +
      "<p>The queen is quiet.</p>" +
      "<p>/kw/  /kw/  /kw/</p>" +
      "<p>Look at the rainbow</p>" +
      "<p>/r/  /r/  /r/</p>" +
      "<p>By the river</p>" +
      "/r/  /r/  /r/</p>",

    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page18/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page18/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page18/E2/Audio/tieude.e2.p18.mp3",
        },
        { url: "img/FriendsPlus/Page18/E2/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page18/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/Q.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/queen.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/quiet.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page18/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/R.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/river.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/rainbow.mp3",
        },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 2",
    id: "SB2-U2-P18-E3",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page18/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page18/E3/3.jpg",
          input: 1,
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/Q.mp3",
        },
        { url: "img/FriendsPlus/Page18/E3/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page18/E3/5.jpg" },
        { url: "img/FriendsPlus/Page18/E3/6.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E3/7.jpg" },
        { url: "img/FriendsPlus/Page18/E3/8.jpg", input: 3 },
        { url: "img/FriendsPlus/Page18/E3/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page18/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page18/E3/11.jpg",
          input: 4,
          audioUrl: "img/FriendsPlus/Page18/E1/Audio/R.mp3",
        },
        { url: "img/FriendsPlus/Page18/E3/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page18/E3/13.jpg" },
        { url: "img/FriendsPlus/Page18/E3/14.jpg", input: 5 },
        { url: "img/FriendsPlus/Page18/E3/15.jpg" },
        { url: "img/FriendsPlus/Page18/E3/16.jpg", input: 6 },
        { url: "img/FriendsPlus/Page18/E3/17.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page18/E3/18.jpg" },
        {
          url: "img/FriendsPlus/Page18/E3/19.jpg",
          input: 7,
          audioUrl: "img/FriendsPlus/Page14/E1/Audio/P.mp3",
        },
        { url: "img/FriendsPlus/Page18/E3/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E3/21.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB2-U2-P18-E4",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page18/E4/Key/answerKeyP18E4.jpg",
    recorder: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page18/E4/3.jpg" }],

      [
        { url: "img/FriendsPlus/Page18/E4/5.jpg" },
        { url: "img/FriendsPlus/Page18/E4/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page18/E4/7.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E4/8.jpg", input: 3 },
        { url: "img/FriendsPlus/Page18/E4/9.jpg" },
        { url: "img/FriendsPlus/Page18/E4/10.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E4/11.jpg", input: 5 },
        { url: "img/FriendsPlus/Page18/E4/12.jpg", input: 6 },
        { url: "img/FriendsPlus/Page18/E4/13.jpg", input: 7 },
        { url: "img/FriendsPlus/Page18/E4/14.jpg", input: 8 },
        { url: "img/FriendsPlus/Page18/E4/15.jpg" },
        { url: "img/FriendsPlus/Page18/E4/16.jpg", input: 9 },
        { url: "img/FriendsPlus/Page18/E4/17.jpg", input: 10 },
        { url: "img/FriendsPlus/Page18/E4/18.jpg" },
        { url: "img/FriendsPlus/Page18/E4/19.jpg", input: 11, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E4/20.jpg", input: 12 },
        { url: "img/FriendsPlus/Page18/E4/21.jpg", input: 13 },
        { url: "img/FriendsPlus/Page18/E4/22.jpg", input: 14 },
        { url: "img/FriendsPlus/Page18/E4/23.jpg", input: 15 },
        { url: "img/FriendsPlus/Page18/E4/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E4/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E4/26.jpg" },
        { url: "img/FriendsPlus/Page18/E4/27.jpg", input: 16 },
        { url: "img/FriendsPlus/Page18/E4/28.jpg", input: 17 },
        { url: "img/FriendsPlus/Page18/E4/29.jpg", input: 18 },
        { url: "img/FriendsPlus/Page18/E4/30.jpg", input: 19 },
        { url: "img/FriendsPlus/Page18/E4/31.jpg" },
        { url: "img/FriendsPlus/Page18/E4/32.jpg", input: 20 },
        { url: "img/FriendsPlus/Page18/E4/33.jpg", input: 21 },
        { url: "img/FriendsPlus/Page18/E4/34.jpg" },
        { url: "img/FriendsPlus/Page18/E4/35.jpg", input: 22 },
        { url: "img/FriendsPlus/Page18/E4/36.jpg", input: 23 },
        { url: "img/FriendsPlus/Page18/E4/37.jpg", input: 24 },
        { url: "img/FriendsPlus/Page18/E4/38.jpg" },
        { url: "img/FriendsPlus/Page18/E4/39.jpg", input: 25, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E4/40.jpg", input: 26 },
        { url: "img/FriendsPlus/Page18/E4/41.jpg", input: 27 },
        { url: "img/FriendsPlus/Page18/E4/42.jpg", input: 28 },
        { url: "img/FriendsPlus/Page18/E4/43.jpg", input: 29 },
        { url: "img/FriendsPlus/Page18/E4/44.jpg", input: 30 },
        { url: "img/FriendsPlus/Page18/E4/45.jpg", input: 31 },
        { url: "img/FriendsPlus/Page18/E4/46.jpg" },
        { url: "img/FriendsPlus/Page18/E4/47.jpg", input: 32 },
        { url: "img/FriendsPlus/Page18/E4/48.jpg", input: 33 },
        { url: "img/FriendsPlus/Page18/E4/49.jpg", input: 34 },
        { url: "img/FriendsPlus/Page18/E4/50.jpg", input: 35 },
        { url: "img/FriendsPlus/Page18/E4/51.jpg" },
        { url: "img/FriendsPlus/Page18/E4/52.jpg", input: 36 },
        { url: "img/FriendsPlus/Page18/E4/53.jpg", input: 37 },
        { url: "img/FriendsPlus/Page18/E4/54.jpg" },
        { url: "img/FriendsPlus/Page18/E4/55.jpg", input: 38 },
        { url: "img/FriendsPlus/Page18/E4/56.jpg", input: 39 },
        { url: "img/FriendsPlus/Page18/E4/57.jpg", input: 40 },
        { url: "img/FriendsPlus/Page18/E4/58.jpg" },
        { url: "img/FriendsPlus/Page18/E4/59.jpg", input: 41, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E4/60.jpg", input: 42 },
        { url: "img/FriendsPlus/Page18/E4/61.jpg", input: 43 },
        { url: "img/FriendsPlus/Page18/E4/62.jpg", input: 44 },
        { url: "img/FriendsPlus/Page18/E4/63.jpg", input: 45, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E4/64.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E4/65.jpg" }],
    ],
  },
};

export default json;
