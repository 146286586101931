import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'




const json = {

  1: { // Exercise num
    unit : 'Unit 2',
    id : 'SB2-U2-P19-E1',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
     
      [
        { url: 'img/FriendsPlus/Page19/E1/1.jpg'},
        { url: 'img/FriendsPlus/Page19/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page19/E1/Audio/tieude.e1.p19.mp3'  },
        { url: 'img/FriendsPlus/Page19/E1/3.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page19/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page19/E1/Audio/13.mp3'  },
        { url: 'img/FriendsPlus/Page19/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page19/E1/Audio/14.mp3'  },
      ],


    ],
  },

  2: { // Exercise num
    unit : 'Unit 2',
    id : 'SB2-U2-P19-E2',
    audio: 'img/FriendsPlus/Page19/Audio/sing.e2.p19.mp3',
    video: '',
    songLyric:
    "<p>Who's hungry? </p>"+
    "<p>Who's hungry? </p>"+
    "<p>Let's count! </p>"+
    "<p>1, 2, 3, 4, 5 </p>"+
    "<p>6, 7, 8, 9, 10 </p>"+
    "<p>11,12, 13 </p>"+
    "<p>13,13, 13! </p>"+
    "<p>Who's thirsty? </p>"+
    "<p>Who's thirsty? </p>"+
    "<p>Let's count! </p>"+
    "<p>1, 2, 3, 4, 5 </p>"+
    "<p>6, 7, 8, 9, 10 </p>"+
    "<p>11,12, 13 ,14</p>"+
    "<p>14,14, 14 </p>",

    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
     
      [
        { url: 'img/FriendsPlus/Page19/E2/1.jpg'},
        { url: 'img/FriendsPlus/Page19/E2/2.jpg',  audioUrl: 'img/FriendsPlus/Page19/Audio/tieude.e2.p19.mp3'   },
        { url: 'img/FriendsPlus/Page19/E2/3.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page19/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page19/Audio/13.mp3'  },
        { url: 'img/FriendsPlus/Page19/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page19/Audio/14.mp3'  },
      ],


    ],
  },

  3: { // Exercise num
    unit : 'Unit 2',
    id : 'SB2-U2-P19-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
     
      [
        { url: 'img/FriendsPlus/Page19/E3/1.jpg'},
      ],
     


    ],
  },
  4: { // Exercise num
    unit : 'Unit 2',
    id : 'SB2-U2-P19-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
     
      [
        { url: 'img/FriendsPlus/Page19/E4/1.jpg'},
      ],
     


    ],
  },

}

export default json;
