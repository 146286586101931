import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB2-U6-P44-E1",
    audio: "img/FriendsPlus/Page44/E1/Audio/audio.e1.p44.mp3",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page44/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page44/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/tieude.e1.p44.mp3",
        },
        { url: "img/FriendsPlus/Page44/E1/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page44/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/V.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/W.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/X.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/Y.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/Z.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/van.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/window.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/box.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/yo-yo.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/13.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/zebra.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/14.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/buzz.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB2-U6-P44-E2",
    audio: "img/FriendsPlus/Page44/E2/Audio/audio.e2.p44.mp3",
    video: "",
    isAllowSubmit: true,
    songLyric:
      "<p>Here is a van. </p>" +
      "<p>/v/ /v/ </p>" +
      "<p>Van </p>" +
      "<p>Here is a window. </p>" +
      "<p>/w/ /w/ </p>" +
      "<p>Window </p>" +
      "<p>Here is a box. </p>" +
      "<p>/ks/ /ks/ </p>" +
      "<p>Box </p>" +
      "<p>Here is a yo-yo. </p>" +
      "<p>/j/ /j/ </p>" +
      "<p>Yo-yo </p>" +
      "<p>Here is a zebra. </p>" +
      "<p>/z/ /z/ </p>" +
      "<p>Zebra</p>",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page44/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page44/E2/Audio/tieude.e2.p44.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/V.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/W.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/X.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/Y.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/Z.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/van.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/window.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/box.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/yo-yo.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/13.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/zebra.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/14.jpg",
          audioUrl: "img/FriendsPlus/Page44/E1/Audio/buzz.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB2-U6-P44-E3",
    audio: "",
    video: "",
    component: UnderLine,
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E3/1.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "SB2-U6-P44-E4",
    audio: "",
    video: "",
    totalInput: 3,
    component: DesignUnderLine,
    exerciseKey: "img/FriendsPlus/Page44/E4/Key/answerKeyP44E4SB2.jpg",
    recorder: false,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E4/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page44/E4/2.jpg" },
        { url: "img/FriendsPlus/Page44/E4/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page44/E4/4.jpg", input: 2 },
        { url: "img/FriendsPlus/Page44/E4/5.jpg", input: 3 },
        { url: "img/FriendsPlus/Page44/E4/6.jpg", input: 4 },
        { url: "img/FriendsPlus/Page44/E4/7.jpg", input: 5 },
        { url: "img/FriendsPlus/Page44/E4/8.jpg", input: 6 },
        { url: "img/FriendsPlus/Page44/E4/9.jpg", input: 7 },
        { url: "img/FriendsPlus/Page44/E4/10.jpg", input: 8 },
        { url: "img/FriendsPlus/Page44/E4/11.jpg", input: 9 },
        { url: "img/FriendsPlus/Page44/E4/12.jpg", input: 10 },
        { url: "img/FriendsPlus/Page44/E4/13.jpg", input: 11 },
        { url: "img/FriendsPlus/Page44/E4/14.jpg", input: 12, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E4/15.jpg", input: 13, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E4/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page44/E4/17.jpg" },
        { url: "img/FriendsPlus/Page44/E4/18.jpg", input: 15 },
        { url: "img/FriendsPlus/Page44/E4/19.jpg", input: 16 },
        { url: "img/FriendsPlus/Page44/E4/20.jpg", input: 17 },
        { url: "img/FriendsPlus/Page44/E4/21.jpg", input: 18 },
        { url: "img/FriendsPlus/Page44/E4/22.jpg", input: 19 },
        { url: "img/FriendsPlus/Page44/E4/23.jpg", input: 20 },
        { url: "img/FriendsPlus/Page44/E4/24.jpg", input: 21 },
        { url: "img/FriendsPlus/Page44/E4/25.jpg", input: 22, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E4/26.jpg" },
      ],

      [{ url: "img/FriendsPlus/Page44/E4/27.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 6",
    id: "SB2-U6-P44-E5",
    audio: "",
    video: "",
    component: UnderLine,
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E5/1.jpg" }],
    ],
  },
};

export default json;
