import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine1";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P12-E1",
    audio: "img/FriendsPlus/Page12/E1/Audio/audio.e1.p12.mp3",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page12/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/tieude.e1.p12.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E1/4.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page12/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/N.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/N.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/nose.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/neck.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page12/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/orange.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/octopus.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P12-E2",
    audio: "img/FriendsPlus/Page12/E2/Audio/audio.e2.p12.mp3",
    video: "",
    songLyric:
      "<p>I’m Nellie.</p>" +
      "<p>/n/ /n/ /n/</p>" +
      "<p>This is my nose.</p>" +
      "<p>/n/ /n/ /n/</p>" +
      "<p>I’m Nellie.</p>" +
      "<p>/n/ /n/ /n/</p>" +
      "<p>This is my neck.</p>" +
      "<p>/n/ /n/ /n/</p>" +
      "<p>I’m Ollie.</p>" +
      "<p>/ɒ/ /ɒ/ /ɒ/</p>" +
      "<p>This is my orange.</p>" +
      "<p>/ɒ/ /ɒ/ /ɒ/</p>" +
      "<p>I’m Ollie.</p>" +
      "<p>/ɒ/ /ɒ/ /ɒ/" +
      "<p>This is my octopus.</p>" +
      "<p>/ɒ/ /ɒ/ /ɒ/",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page12/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page12/E2/Audio/tieude.e2.p12.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E1/4.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page12/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/N.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/N.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/nose.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/neck.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page12/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/orange.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/octopus.mp3",
        },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P12-E3",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page12/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/3.jpg",
          input: 1,
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/5.jpg",
          input: 2,
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/N.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/6.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/7.jpg",
          input: 3,
          audioUrl: "img/FriendsPlus/Page12/E1/Audio/O.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E3/9.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P12-E4",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 11,
    exerciseKey: "img/FriendsPlus/Page12/E4/Key/answerKeyP12E4.jpg",
    recorder: false,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page12/E4/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page12/E4/2.jpg" },
        { url: "img/FriendsPlus/Page12/E4/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page12/E4/4.jpg", input: 2 },
        { url: "img/FriendsPlus/Page12/E4/5.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/6.jpg", input: 4 },
        { url: "img/FriendsPlus/Page12/E4/7.jpg", input: 5 },
        { url: "img/FriendsPlus/Page12/E4/8.jpg", input: 6 },
        { url: "img/FriendsPlus/Page12/E4/9.jpg", input: 7 },
        { url: "img/FriendsPlus/Page12/E4/10.jpg", input: 8 },
        { url: "img/FriendsPlus/Page12/E4/11.jpg", input: 9 },
        { url: "img/FriendsPlus/Page12/E4/12.jpg", input: 10 },
        { url: "img/FriendsPlus/Page12/E4/13.jpg", input: 11 },
        { url: "img/FriendsPlus/Page12/E4/14.jpg", input: 12 },
        { url: "img/FriendsPlus/Page12/E4/15.jpg", input: 13 },
        { url: "img/FriendsPlus/Page12/E4/16.jpg", input: 14 },
        { url: "img/FriendsPlus/Page12/E4/17.jpg", input: 15 },
        { url: "img/FriendsPlus/Page12/E4/18.jpg", input: 16 },
        { url: "img/FriendsPlus/Page12/E4/19.jpg", input: 17, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/20.jpg", input: 18, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/21.jpg", input: 19 },
        { url: "img/FriendsPlus/Page12/E4/22.jpg", input: 20 },
        { url: "img/FriendsPlus/Page12/E4/23.jpg", input: 21 },
        { url: "img/FriendsPlus/Page12/E4/24.jpg", input: 22, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/25.jpg", input: 23 },
        { url: "img/FriendsPlus/Page12/E4/26.jpg", input: 24 },
        { url: "img/FriendsPlus/Page12/E4/27.jpg", input: 25 },
        { url: "img/FriendsPlus/Page12/E4/28.jpg", input: 26, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/29.jpg", input: 27 },
        { url: "img/FriendsPlus/Page12/E4/30.jpg", input: 28 },
        { url: "img/FriendsPlus/Page12/E4/31.jpg", input: 29 },
        { url: "img/FriendsPlus/Page12/E4/32.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E4/33.jpg" },
        { url: "img/FriendsPlus/Page12/E4/34.jpg", input: 30 },
        { url: "img/FriendsPlus/Page12/E4/35.jpg", input: 31 },
        { url: "img/FriendsPlus/Page12/E4/36.jpg", input: 32, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/37.jpg", input: 33 },
        { url: "img/FriendsPlus/Page12/E4/38.jpg", input: 34 },
        { url: "img/FriendsPlus/Page12/E4/39.jpg", input: 35 },
        { url: "img/FriendsPlus/Page12/E4/40.jpg", input: 36 },
        { url: "img/FriendsPlus/Page12/E4/41.jpg", input: 37 },
        { url: "img/FriendsPlus/Page12/E4/42.jpg", input: 38 },
        { url: "img/FriendsPlus/Page12/E4/43.jpg", input: 39 },
        { url: "img/FriendsPlus/Page12/E4/44.jpg", input: 40 },
        { url: "img/FriendsPlus/Page12/E4/45.jpg", input: 41 },
        { url: "img/FriendsPlus/Page12/E4/46.jpg", input: 42 },
        { url: "img/FriendsPlus/Page12/E4/47.jpg", input: 43 },
        { url: "img/FriendsPlus/Page12/E4/48.jpg", input: 44 },
        { url: "img/FriendsPlus/Page12/E4/49.jpg", input: 45, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/50.jpg", input: 46 },
        { url: "img/FriendsPlus/Page12/E4/51.jpg", input: 47 },
        { url: "img/FriendsPlus/Page12/E4/52.jpg", input: 48, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/53.jpg", input: 49 },
        { url: "img/FriendsPlus/Page12/E4/54.jpg", input: 50 },
        { url: "img/FriendsPlus/Page12/E4/55.jpg", input: 51 },
        { url: "img/FriendsPlus/Page12/E4/56.jpg", input: 52, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/57.jpg", input: 53 },
        { url: "img/FriendsPlus/Page12/E4/58.jpg", input: 54 },
        { url: "img/FriendsPlus/Page12/E4/59.jpg", input: 55 },
        { url: "img/FriendsPlus/Page12/E4/60.jpg", input: 56, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/61.jpg", input: 57 },
        { url: "img/FriendsPlus/Page12/E4/62.jpg", input: 58 },
        { url: "img/FriendsPlus/Page12/E4/63.jpg", input: 59, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/64.jpg", input: 60 },
        { url: "img/FriendsPlus/Page12/E4/65.jpg", input: 61 },
        { url: "img/FriendsPlus/Page12/E4/66.jpg", input: 62 },
        { url: "img/FriendsPlus/Page12/E4/67.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E4/68.jpg" }],
    ],
  },
};

export default json;
