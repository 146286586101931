import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {

  1: { // Exercise num
    unit : 'Fluency Time! 2',
    id : 'SB2-U6-P48-E1',
    audio: 'img/FriendsPlus/Page48/Audio/audio.e1.p48.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page48/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/2.jpg', audioUrl : 'img/FriendsPlus/Page48/Audio/tieude.e1.p48.mp3' },
        { url: 'img/FriendsPlus/Page48/E1/3.jpg' },
      ],
      [
       
        { url: 'img/FriendsPlus/Page48/E1/4.jpg', audioUrl : 'img/FriendsPlus/Page48/Audio/anh1.e1.p48.mp3' },
        { url: 'img/FriendsPlus/Page48/E1/5.jpg', audioUrl : 'img/FriendsPlus/Page48/Audio/anh2.e1.p48.mp3' },
      ],

    ],
  },

  2: { // Exercise num
    unit : 'Fluency Time! 2',
    id : 'SB2-U6-P48-E2',
    audio: 'img/FriendsPlus/Page48/Audio/audio.e2.p48.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page48/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/2.jpg', audioUrl : 'img/FriendsPlus/Page48/Audio/tieude.e2.p48.mp3' },
        { url: 'img/FriendsPlus/Page48/E2/3.jpg' },
      ],
      [
       
        { url: 'img/FriendsPlus/Page48/E2/4.jpg'},
      ],

    ],
  },

  3: { // Exercise num
    unit : 'Fluency Time! 2',
    id : 'SB2-U6-P48-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page48/E3/1.jpg'},
      ],

    ],
  },


}
export default json;
