import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Time! 1",
    id: "SB2-U3-P29-E1",
    audio: "img/FriendsPlus/Page29/E1/Audio/audio.e1.p29.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page29/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/tieude.e1.p29.mp3",
        },
        { url: "img/FriendsPlus/Page29/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/plus.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/equals.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/addition problem.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Time! 1",
    id: "SB2-U3-P29-E2",
    audio: "img/FriendsPlus/Page29/E2/Audio/anh.e2.p29.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page29/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page29/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/tieude.e1.p29.mp3",
        },
        { url: "img/FriendsPlus/Page29/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E2/4.jpg",
          audioUrl: "img/FriendsPlus/Page29/E2/Audio/anh.e2.p29.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Fluency Time! 1",
    id: "SB2-U3-P29-E3",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page29/E3/1.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Fluency Time! 1",
    id: "SB2-U3-P29-E4",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page29/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page29/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page29/E4/3.jpg",
          input: 1,
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E4/4.jpg",
          input: 1,
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E4/5.jpg",
          input: 1,
          audioUrl: "img/FriendsPlus/Page29/E1/Audio/bus.mp3",
        },
        { url: "img/FriendsPlus/Page29/E4/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page29/E4/7.jpg" }],
    ],
  },
};

export default json;
