import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Culture 3",
    id: "SB2-C3-P54-E1",
    audio: "img/FriendsPlus/Page54/E1/Audio/audio.e1.p54.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page54/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page54/E1/Audio/tieude.e1.p54.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page54/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page54/E1/Audio/baseball.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page54/E1/Audio/basketball.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page54/E1/Audio/hockey.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page54/E1/Audio/badminton.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Culture 3",
    id: "SB2-C3-P54-E2",
    audio: "img/FriendsPlus/Page54/E2/Audio/audio.e2.p54.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page54/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page54/E2/Audio/tieude.e2.p54.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page54/E2/4.jpg",
          audioUrl: "img/FriendsPlus/Page54/E2/Audio/anh1.e2.p54.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E2/5.jpg",
          audioUrl: "img/FriendsPlus/Page54/E2/Audio/anh2.e2.p54.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E2/6.jpg",
          audioUrl: "img/FriendsPlus/Page54/E2/Audio/anh3.e2.p54.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E2/7.jpg",
          audioUrl: "img/FriendsPlus/Page54/E2/Audio/anh4.e2.p54.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Culture 3",
    id: "SB2-C3-P54-E3",
    audio: "",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page54/E3/1.jpg" }],
    ],
  },
};

export default json;
