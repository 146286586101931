import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {

  1: { // Exercise num
    unit : 'Culture 1',
    id : 'SB2-C1-P52-E1',
    audio: 'img/FriendsPlus/Page52/Audio/audio.e1.p52.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/tieude.e1.p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/dog.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/cat.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hamster.mp3'  },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/fish.mp3' },
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Culture 1',
    id : 'SB2-C1-P52-E2',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Culture 1',
    id : 'SB2-C1-P52-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page52/E3/1.jpg' },
      ],
    ],
  },


}
export default json;
