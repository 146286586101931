import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P7-E1',
    audio: 'img/FriendsPlus/Page7/Audio/audio.e1.p7.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page7/Audio/tieude.e1.p7.mp3' },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/9.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/12.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/13.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/17.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P7-E2',
    audio: 'img/FriendsPlus/Page7/Audio/sing.e2.p7.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    songLyric: 
    '<p>Count, count, </p>'+
    '<p>Count with me, </p>'+
    '<p>Count the apples in the tree.  </p>'+
    '<p>1, 2, 3, 4, 5 </p>'+
    '<p>Oh my! </p>'+
    '<p>Count, count, </p>'+
    '<p>Count with me, </p>'+
    '<p>Count the apples in the tree. </p>'+
    '<p>6, 7, 8, 9, 10 </p>'+
    '<p>Again! </p>'+
    '<p>Count, count, </p>'+
    '<p>Count with me, </p>'+
    '<p>Count the apples in the tree. </p>'+
    '<p>1, 2, 3, 4, 5 </p>'+
    '<p>Oh my! </p>'+
    '<p>Count, count, </p>'+
    '<p>Count with me, </p>'+
    '<p>Count the apples in the tree. </p>'+
    '<p>6, 7, 8, 9, 10 </p>',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/tieude.e2.p7.mp3' },
        { url: 'img/FriendsPlus/Page7/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/9.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/12.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/13.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg', },
        { url: 'img/FriendsPlus/Page7/E1/17.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P7-E3',
    audio: '',
    video: '',
    component: D1,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    isLargeInput: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/3.jpg' ,input: true, answer: '4'},
        { url: 'img/FriendsPlus/Page7/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/5.jpg' ,input: true, answer: '6'},
        { url: 'img/FriendsPlus/Page7/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/7.jpg',input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page7/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/9.jpg',input: true, answer: '7' },
        { url: 'img/FriendsPlus/Page7/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/11.jpg' ,input: true, answer: '8'},
        { url: 'img/FriendsPlus/Page7/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/13.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P7-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E4/1.jpg' },
      ],
    ]
  },
}

export default json;