import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';




const json = {
    1: { // Exercise num
        unit: 'Unit 5',
        id: 'SB2-U5-P38-E1',
        audio: 'img/FriendsPlus/Page38/Audio/audio.e1.p38.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/tieude.e1.p38.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/N.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/O.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/P.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/Q.mp3' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/nurse (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/orange (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/pen (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/queen (mp3cut.net).mp3' },
            ],
        ],
    },

    2: { // Exercise num
        unit: 'Unit 5',
        id: 'SB2-U5-P38-E2',
        audio: 'img/FriendsPlus/Page38/Audio/audio.e2.p38.mp3',
        video: '',
        songLyric:
            "<p>This is my nurse. </p>" +
            "<p>/n/ /n/ </p>" +
            "<p>Nurse </p>" +
            "<p>This is my orange. </p>" +
            "<p>/ɔː/  /ɔː / </p>" +
            "<p> Orange </p>" +
            "<p>This is my pen. </p>" +
            "<p>/p/ /p/ </p>" +
            "<p>Pen </p>" +
            "<p>Where is the queen? </p>" +
            "<p>/kw/ /kw/ </p>" +
            "<p>Queen</p>",
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/tieude.e2.p38.mp3' },
                { url: 'img/FriendsPlus/Page38/E2/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/N.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/O.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/P.mp3' },
                { url: 'img/FriendsPlus/Page38/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/Q.mp3' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/nurse (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/orange (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/pen (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/queen (mp3cut.net).mp3' },
            ],
        ],
    },

    3: { // Exercise num
        unit: 'Unit 5',
        id: 'SB2-U5-P38-E3',
        audio: 'img/FriendsPlus/Page38/Audio/audio.e3.p38.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E3/1.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/tieude.e3.p38.mp3' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/3.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/N.mp3' },
                { url: 'img/FriendsPlus/Page38/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/lion (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/P.mp3' },
                { url: 'img/FriendsPlus/Page38/E3/8.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/lollipop (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E3/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/11.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/lion (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E3/12.jpg', audioUrl: 'img/FriendsPlus/Page38/Audio/lollipop (mp3cut.net).mp3' },
                { url: 'img/FriendsPlus/Page38/E3/13.jpg' },
            ],
        ],
    },

    4: { // Exercise num
        unit: 'Unit 5',
        id: 'SB2-U5-P38-E4',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page38/E4/Key/answerKey.png',
        component: DesignUnderLine,
        totalInput: 3,
        // exerciseKey: '',
        recorder: false,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page38/E4/4.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page38/E4/5.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page38/E4/6.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page38/E4/7.jpg', input: 5 },
                { url: 'img/FriendsPlus/Page38/E4/8.jpg', input: 6 },
                { url: 'img/FriendsPlus/Page38/E4/9.jpg', input: 7, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E4/10.jpg', input: 8 },
                { url: 'img/FriendsPlus/Page38/E4/11.jpg', input: 9 },
                { url: 'img/FriendsPlus/Page38/E4/12.jpg', input: 10 },
                { url: 'img/FriendsPlus/Page38/E4/13.jpg', input: 11 },
                { url: 'img/FriendsPlus/Page38/E4/14.jpg', input: 12 },
                { url: 'img/FriendsPlus/Page38/E4/15.jpg', input: 13 },
                { url: 'img/FriendsPlus/Page38/E4/16.jpg', input: 14 },
                { url: 'img/FriendsPlus/Page38/E4/17.jpg', input: 15 },
                { url: 'img/FriendsPlus/Page38/E4/18.jpg', input: 16 },
                { url: 'img/FriendsPlus/Page38/E4/19.jpg', input: 17, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E4/20.jpg', input: 18 },
                { url: 'img/FriendsPlus/Page38/E4/21.jpg', input: 19, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E4/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/23.jpg' },
            ],
        ],
    },

    5: { // Exercise num
        unit: 'Unit 5',
        id: 'SB2-U5-P38-E5',
        audio: '',
        video: '',
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E5/1.jpg' },
            ],
        ],
    },

}

export default json;
