import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num

    unit: "Unit 1",
    id: "SB2-U1-P10-E1",
    audio: "img/FriendsPlus/Page10/E1/Audio/audio.e1.p10.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page10/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/tieude.e1.p10.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/mom.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/dad.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/brother.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/sister.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandpa.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandma.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P10-E2",
    audio: "img/FriendsPlus/Page10/E2/Audio/audio.e2.p10.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    songLyric:
      "<p>mom, mom, mom </p>" +
      "<p>dad, dad, dad </p>" +
      "<p>brother, brother, brother </p>" +
      "<p>sister, sister, sister </p>" +
      "<p>grandpa, grandpa, grandpa </p>" +
      "<p>grandma, grandma, grandma </p>",
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page10/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page10/E2/Audio/tieude.e2.p10.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/mom.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/dad.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/brother.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/sister.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandpa.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandma.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P10-E3",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page10/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page10/E3/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E3/3.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/4.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandpa.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/5.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page10/E3/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E3/7.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/8.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/mom.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page10/E3/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E3/11.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/12.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/grandma.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page10/E3/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E3/15.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/16.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/sister.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/17.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page10/E3/18.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E3/19.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/20.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/dad.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/21.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/22.jpg",
          audioUrl: "img/FriendsPlus/Page10/E1/Audio/brother.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page10/E3/24.jpg" }],
    ],
  },
};

export default json;
