import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P21-E1',
    audio: 'img/FriendsPlus/Page21/Audio/audio.e1.p21.mp3',
    video: 'Videos/P21E1.mp4',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/3.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/tieude.e1.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/4.jpg', videoUrl: 'Videos/P21E1.mp4' },
        { url: 'img/FriendsPlus/Page21/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh1.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh2.e3.p21.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh3.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh4.e3.p21.mp3' },
      ],


    ],
  },

  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P21-E2',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/3.jpg', audioUrl: '' },
        { url: 'img/FriendsPlus/Page21/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh1.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh2.e3.p21.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh3.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh4.e3.p21.mp3' },
      ],


    ],
  },

  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P21-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page21/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/tieude.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh1.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh2.e3.p21.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh3.e3.p21.mp3' },
        { url: 'img/FriendsPlus/Page21/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page21/Audio/anh4.e3.p21.mp3' },
      ],


    ],
  },


}
export default json;
