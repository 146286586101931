import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';



const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P26-E1',
    audio: 'img/FriendsPlus/Page26/Audio/audio.e1.p26.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page26/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/tieude.e1.p26.mp3'},
        { url: 'img/FriendsPlus/Page26/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E1/4.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/V.mp3'},
        { url: 'img/FriendsPlus/Page26/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/violin (mp3cut.net).mp3'},
        { url: 'img/FriendsPlus/Page26/E1/6.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/vase (mp3cut.net).mp3'},
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P26-E2',
    audio: 'img/FriendsPlus/Page26/Audio/audio.e2.p26.mp3',
    video: '',
    songLyric:
    "<p>I'm Victor. </p>"+
    "<p>I have a violin. </p>"+
    "<p>/v/ /v/ /v/ I have a violin. </p>"+
    "<p>I'm Vicky. </p>"+
    "<p>I have a vase. </p>"+
    "<p>/v/ /v/ /v/ </p>"+
    "<p>I have a vase.</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page26/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/tieude.e2.p26.mp3'},
        { url: 'img/FriendsPlus/Page26/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E1/4.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/V.mp3'},
        { url: 'img/FriendsPlus/Page26/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/violin (mp3cut.net).mp3'},
        { url: 'img/FriendsPlus/Page26/E1/6.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/vase (mp3cut.net).mp3'},
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P26-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page26/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/V.mp3'},
        { url: 'img/FriendsPlus/Page26/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E3/5.jpg' , },
        { url: 'img/FriendsPlus/Page26/E3/6.jpg' , audioUrl: 'img/FriendsPlus/Page26/Audio/V.mp3'},
        { url: 'img/FriendsPlus/Page26/E3/7.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P26-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page26/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E4/2.jpg'},
        { url: 'img/FriendsPlus/Page26/E4/3.jpg', input : 1 },
        { url: 'img/FriendsPlus/Page26/E4/4.jpg', input : 2 },
        { url: 'img/FriendsPlus/Page26/E4/5.jpg' , input : 3},
        { url: 'img/FriendsPlus/Page26/E4/6.jpg' , input : 4},
        { url: 'img/FriendsPlus/Page26/E4/7.jpg'  , input : 5},
        { url: 'img/FriendsPlus/Page26/E4/8.jpg' , input : 6},
        { url: 'img/FriendsPlus/Page26/E4/9.jpg'  },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E4/10.jpg'  },
        { url: 'img/FriendsPlus/Page26/E4/11.jpg' , input : 1},
        { url: 'img/FriendsPlus/Page26/E4/12.jpg'  , input : 2},
        { url: 'img/FriendsPlus/Page26/E4/13.jpg'  , input : 3 },
        { url: 'img/FriendsPlus/Page26/E4/14.jpg' , input : 4},
        { url: 'img/FriendsPlus/Page26/E4/15.jpg'  , input : 5},
        { url: 'img/FriendsPlus/Page26/E4/16.jpg' , input : 6},
        { url: 'img/FriendsPlus/Page26/E4/17.jpg'  },
      ],
      [
        { url: 'img/FriendsPlus/Page26/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page26/E4/19.jpg' , input : 1},
        { url: 'img/FriendsPlus/Page26/E4/20.jpg'  , input : 2},
        { url: 'img/FriendsPlus/Page26/E4/21.jpg'  , input : 3},
        { url: 'img/FriendsPlus/Page26/E4/22.jpg' , input : 4},
        { url: 'img/FriendsPlus/Page26/E4/23.jpg'  , input : 5},
        { url: 'img/FriendsPlus/Page26/E4/24.jpg' , input : 6},
        { url: 'img/FriendsPlus/Page26/E4/25.jpg'  },
      ],
    ],
  },

}

export default json;
