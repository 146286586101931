import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P25-E1',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/tieude.e1.p25.mp3'},
        { url: 'img/FriendsPlus/Page25/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' , audioUrl: 'img/FriendsPlus/Page25/Audio/15.mp3'},
        { url: 'img/FriendsPlus/Page25/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page25/Audio/16.mp3'},
       
      ],
    ],
  },
  2: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P25-E2',
    audio: 'img/FriendsPlus/Page25/Audio/sing.e2.p25.mp3',
    video: '',
    songLyric:
    "<p>Fifteen blue dresses </p>"+
    "<p>With a star. </p>"+
    "<p>Are these her dresses? </p>"+
    "<p>Yes, they are! </p>"+
    "<p>1, 2, 3, 4, 5 </p>"+
    "<p>6, 7, 8, 9, 10 </p>"+
    "<p>11, 12, 13, 14, 15 </p>"+
    "<p>Fifteen dresses </p>"+
    "<p>With a star. </p>"+
    "<p>Sixteen pants </p>"+
    "<p>With a sun. </p>"+
    "<p>Are these his pants? </p>"+
    "<p>No, they aren't </p>"+
    "<p>1, 2, 3, 4, 5 </p>"+
    "<p>6, 7, 8, 9, 10 </p>"+
    "<p>11, 12, 13, 14, 15, 16 </p>"+
    "<p>Sixteen pants </p>"+
    "<p>With a sun. </p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page25/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page25/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/tieude.e2.p25.mp3'},
        { url: 'img/FriendsPlus/Page25/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' , audioUrl: 'img/FriendsPlus/Page25/Audio/15.mp3'},
        { url: 'img/FriendsPlus/Page25/E1/5.jpg' , audioUrl: 'img/FriendsPlus/Page25/Audio/16.mp3'},
       
      ],
    ],
  },
  3: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P25-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page25/E3/1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P25-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page25/E4/1.jpg' },
      ],
    ],
  },
}

export default json;