import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P34-E1",
    audio: "img/FriendsPlus/Page34/E1/Audio/audio.e1.p34.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page34/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page34/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/tieude.e1.p34.mp3",
        },
        { url: "img/FriendsPlus/Page34/E1/3.jpg" },
        { url: "img/FriendsPlus/Page34/E1/4.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Y.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/yogurt.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/yo-yo.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page34/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Z.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/zebra.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/zoo.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P34-E2",
    audio: "img/FriendsPlus/Page34/E2/Audio/audio.e2.p34.mp3",
    video: "",
    isAllowSubmit: true,
    songLyric:
      "<p>I like yogurt. </p>" +
      "<p>/j/ /j/ /j/ </p>" +
      "<p>I like my yo-yo </p>" +
      "<p>/j/ /j/ /j/ </p>" +
      "<p>Zebras Zebras at the zoo </p>" +
      "<p>Z says the zebra at the zoo</p>" +
      "<p> /z/ /z/ /z/</p>" +
      "<p> /z/ /z/ /z/</p>",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page34/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page34/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page34/E2/Audio/tieude.e2.p34.mp3",
        },
        { url: "img/FriendsPlus/Page34/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Y.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/yogurt.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/yo-yo.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page34/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Z.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/zebra.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/zoo.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P34-E3",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page34/E3/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page34/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page34/E3/3.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Z.mp3",
          input: 1,
        },
        { url: "img/FriendsPlus/Page34/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page34/E3/5.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Y.mp3",
          input: 2,
        },
        { url: "img/FriendsPlus/Page34/E3/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page34/E3/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page34/E3/8.jpg" },
        {
          url: "img/FriendsPlus/Page34/E3/9.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Y.mp3",
          input: 3,
        },
        { url: "img/FriendsPlus/Page34/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page34/E3/11.jpg",
          audioUrl: "img/FriendsPlus/Page34/E1/Audio/Z.mp3",
          input: 4,
        },
        { url: "img/FriendsPlus/Page34/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page34/E3/13.jpg" }],
    ],
  },
};

export default json;
