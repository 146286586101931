import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';




const json = {
  1: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P39-E1',
    audio: 'img/FriendsPlus/Page39/Audio/audio.e1.p39.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/tieude.e1.p39.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/19.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/20.mp3' },
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P39-E2',
    audio: 'img/FriendsPlus/Page39/Audio/sing.e2.p39.mp3',
    video: '',
    songLyric:
    "<p>What do we want to do today? </p>" +
    "<p>Let's go to the park to play! </p>" +
    "<p>There are nineteen goals and nineteen slides </p>" +
    "<p>And nineteen trees where we can hide. </p>" +
    "<p>What do we want to do today? </p>" +
    "<p>Let's go to the park to play! </p>" +
    "<p>But how many Frisbees can you see? </p>" +
    "<p>Will you count them along with me? </p>" +
    "<p>One, two, three, four, five </p>" +
    "<p>Six, seven, eight, nine, ten </p>" +
    "<p>Eleven, twelve, thirteen, fourteen, </p>" +
    "<p>Fifteen, sixteen, seventeen, eighteen </p>" +
    "<p>Nineteen, twenty! Twenty Frisbees!</p>" ,
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page39/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/tieude.e2.p39.mp3' },
        { url: 'img/FriendsPlus/Page39/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/19.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/20.mp3' },
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P39-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKeyP39E3.jpg',
    // exerciseKey: '',
    inputHeight:'100%',
    fontSize: 45,
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page39/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E3/2.jpg', },
        { url: 'img/FriendsPlus/Page39/E3/3.jpg' ,input: true, answer: '10'},
        { url: 'img/FriendsPlus/Page39/E3/4.jpg', },
        { url: 'img/FriendsPlus/Page39/E3/5.jpg',input: true, answer: '9'},
        { url: 'img/FriendsPlus/Page39/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/7.jpg',input: true, answer: '19' },
        { url: 'img/FriendsPlus/Page39/E3/8.jpg', },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E3/10.jpg', },
        { url: 'img/FriendsPlus/Page39/E3/11.jpg',input: true, answer: '12' },
        { url: 'img/FriendsPlus/Page39/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/13.jpg',input: true, answer: '8' },
        { url: 'img/FriendsPlus/Page39/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/15.jpg',input: true, answer: '20' },
        { url: 'img/FriendsPlus/Page39/E3/16.jpg', },
      ],
    ],
  },

  4: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P39-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page39/E4/1.jpg' },
      ],
    ],
  },

}

export default json;
