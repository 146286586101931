import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P31-E1",
    audio: "img/FriendsPlus/Page31/E1/Audio/audio.e1.p31.mp3",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page31/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page31/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page31/E1/Audio/tieude.e1.p31.mp3",
        },
        { url: "img/FriendsPlus/Page31/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page31/E1/Audio/anh1.e1.p31.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page31/E1/Audio/anh2.e1.p31.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P31-E2",
    audio: "img/FriendsPlus/Page31/E2/Audio/sing.e2.p31.mp3",
    video: "",
    isAllowSubmit: true,
    songLyric:
      "<p>School, school, I go to school </p>" +
      "<p>I go to school in the morning!! </p>" +
      "<p>On Monday and Tuesday </p>" +
      "<p>I walk to school </p>" +
      "<p>On Wednesday I go by bike </p>" +
      "<p>On Thursday and Friday </p>" +
      "<p>I go with my friends </p>" +
      "<p>We go by bus </p>" +
      "<p>School, school, I go to school </p>" +
      "<p>I go to school in the morning!!</p>",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page31/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page31/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page31/E2/Audio/tieude.e2.p31.mp3",
        },
        { url: "img/FriendsPlus/Page31/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E2/4.jpg",
          audioUrl: "img/FriendsPlus/Page31/E2/Audio/sing.e2.p31.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P31-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 40,
    inputHeight: "100%",
    hideBtnFooter: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page31/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E3/2.jpg" },
        { url: "img/FriendsPlus/Page31/E3/3.jpg", input: true, answer: "boat" },
        { url: "img/FriendsPlus/Page31/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E4/1.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P31-E4",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page31/E4/1.jpg" }],
    ],
  },
};

export default json;
