import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'




const json = {

  1: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P41-E1',
    audio: 'img/FriendsPlus/Page41/Audio/audio.e1.p41.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page41/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/2.jpg'},
        { url: 'img/FriendsPlus/Page41/E1/3.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/tieude.e1.p41.mp3'  },
        { url: 'img/FriendsPlus/Page41/E1/4.jpg',videoUrl:'Videos/P41E1.mp4'},
        { url: 'img/FriendsPlus/Page41/E1/5.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/6.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh1.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E1/8.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh2.e3.p41.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh3.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E1/10.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh4.e3.p41.mp3' },
      ],
    ],
  },
 

  2: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P41-E2',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page41/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/2.jpg'},
        { url: 'img/FriendsPlus/Page41/E2/3.jpg'},
        { url: 'img/FriendsPlus/Page41/E2/4.jpg'},
        { url: 'img/FriendsPlus/Page41/E2/5.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/6.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh1.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E1/8.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh2.e3.p41.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh3.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E1/10.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh4.e3.p41.mp3' },
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P41-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page41/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E3/2.jpg'},
        { url: 'img/FriendsPlus/Page41/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/tieude.e3.p41.mp3'  },
        { url: 'img/FriendsPlus/Page41/E3/4.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E3/5.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page41/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh1.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E3/7.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh2.e3.p41.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E3/8.jpg', audioUrl: 'img/FriendsPlus/Page41/Audio/anh3.e3.p41.mp3' },
        { url: 'img/FriendsPlus/Page41/E3/9.jpg' , audioUrl: 'img/FriendsPlus/Page41/Audio/anh4.e3.p41.mp3' },
      ],
    ],
  },

}

export default json;
