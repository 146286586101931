import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P13-E1",
    audio: "img/FriendsPlus/Page13/E1/Audio/audio.e1.p13.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page13/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page13/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page13/E1/Audio/tieude.e1.p13.mp3",
        },
        { url: "img/FriendsPlus/Page13/E1/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page13/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page13/E1/Audio/11.mp3",
        },
        {
          url: "img/FriendsPlus/Page13/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page13/E1/Audio/12.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P13-E2",
    audio: "img/FriendsPlus/Page13/E2/Audio/sing.e2.p13.mp3",
    isAllowSubmit: true,
    songLyric:
      "<p>Hey look at my mom arrive! </p>" +
      "<p>Now let's count all the moms in line. </p>" +
      "<p>1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 </p>" +
      "<p>How many? 11! </p>" +
      "<p>Hey look at my dad arrive! </p>" +
      "<p>Now let's count all the dads in line. </p>" +
      "<p>1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12 </p>" +
      "<p>How many? 12</p>",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page13/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page13/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page13/E2/Audio/tieude.e2.p13.mp3",
        },
        { url: "img/FriendsPlus/Page13/E2/3.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page13/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page13/E1/Audio/11.mp3",
        },
        {
          url: "img/FriendsPlus/Page13/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page13/E1/Audio/12.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P13-E3",
    audio: "",

    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page13/E3/Key/1.png",
    recorder: true,

    isLargeInput: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page13/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page13/E3/2.jpg" },
        { url: "img/FriendsPlus/Page13/E3/3.jpg", input: true, answer: "12" },
        { url: "img/FriendsPlus/Page13/E3/4.jpg" },
        { url: "img/FriendsPlus/Page13/E3/5.jpg", input: true, answer: "11" },
        { url: "img/FriendsPlus/Page13/E3/6.jpg" },
        { url: "img/FriendsPlus/Page13/E3/7.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page13/E3/8.jpg" },
        { url: "img/FriendsPlus/Page13/E3/9.jpg", input: true, answer: "7" },
        { url: "img/FriendsPlus/Page13/E3/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page13/E3/11.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P13-E4",
    audio: "",
    video: "",
    component: UnderLine,
    isDisabledSubmit: false,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page13/E4/1.jpg" }],
    ],
  },
};

export default json;
