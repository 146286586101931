import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P8-E1",
    audio: "img/FriendsPlus/Page8/Audio/audio.e1.p8.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page8/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/tieude.e1.p8.mp3",
        },
        { url: "img/FriendsPlus/Page8/E1/3.1.jpg" },
        { url: "img/FriendsPlus/Page8/E1/3.2.jpg" }, //, videoUrl:'Videos/P8E1.mp4'
        { url: "img/FriendsPlus/Page8/E1/3.3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/G.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/H.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/I.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/J.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/goat (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/dog.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/hat (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/13.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/ink (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/14.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/jelly (mp3cut.net).mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/16.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/K.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/17.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/L.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/18.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/M.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/20.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/kite (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/21.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/yak (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/22.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/lion (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/23.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/ill (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/24.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/mom (mp3cut.net).mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P8-E2",
    audio: "img/FriendsPlus/Page8/Audio/audio.e2.p8.mp3",
    video: "",
    songLyric:
      "<p>What's that over there? </p>" +
      "<p>It's a /g/, /g/, goat. </p>" +
      "<p>And what's this here? </p>" +
      "<p>It's a /h/, /h/, hat. </p>" +
      "<p>What's that over there? </p>" +
      "<p>It's /i/, /i/, ink. </p>" +
      "<p>And what's this here? </p>" +
      "<p>It's /ʤ/, /ʤ/, jelly. </p>" +
      "<p>What's that over there?</p>" +
      "<p>It's a /k/, /k/, kite. </p>" +
      "<p>And what's this here? </p>" +
      "<p>It's a /l/, /l/, lion. </p>" +
      "<p>And who's coming now? </p>" +
      "<p>It's /m/, /m/, mom!</p>",

    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page8/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page8/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/tieude.e2.p8.mp3",
        },
        { url: "img/FriendsPlus/Page8/E2/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/G.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/H.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/I.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/J.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/goat (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/11.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/dog.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/12.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/hat (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/13.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/ink (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/14.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/jelly (mp3cut.net).mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/16.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/K.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/17.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/L.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/18.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/M.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page8/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/20.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/kite (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/21.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/yak (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/22.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/lion (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/23.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/ill (mp3cut.net).mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/24.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/mom (mp3cut.net).mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P8-E3",
    audio: "img/FriendsPlus/Page8/Audio/audio.e3.p8.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 7,
    exerciseKey: "img/FriendsPlus/Page8/E3/Key/answerKeyP8E3.jpg",
    recorder: false,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page8/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page8/E3/2.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/tieude.e3.p8.mp3",
        },
        { url: "img/FriendsPlus/Page8/E3/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E3/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page8/E3/5.jpg" },
        { url: "img/FriendsPlus/Page8/E3/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/7.jpg" },
        { url: "img/FriendsPlus/Page8/E3/8.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/9.jpg" },
        { url: "img/FriendsPlus/Page8/E3/10.jpg", input: 3, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/11.jpg" },
        { url: "img/FriendsPlus/Page8/E3/12.jpg", input: 4, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/13.jpg" },
        { url: "img/FriendsPlus/Page8/E3/14.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/15.jpg" },
        { url: "img/FriendsPlus/Page8/E3/16.jpg", input: 6, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/17.jpg" },
        { url: "img/FriendsPlus/Page8/E3/18.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page8/E3/20.jpg" },
        { url: "img/FriendsPlus/Page8/E3/21.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/22.jpg" },
        { url: "img/FriendsPlus/Page8/E3/23.jpg", input: 2, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/24.jpg" },
        { url: "img/FriendsPlus/Page8/E3/25.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/26.jpg" },
        { url: "img/FriendsPlus/Page8/E3/27.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/28.jpg" },
        { url: "img/FriendsPlus/Page8/E3/29.jpg", input: 5, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/30.jpg" },
        { url: "img/FriendsPlus/Page8/E3/31.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E3/32.jpg" },
        { url: "img/FriendsPlus/Page8/E3/33.jpg", input: 7, isCorrect: false },
        { url: "img/FriendsPlus/Page8/E3/34.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E3/35.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Starter",
    audio: "",
    video: "",
    totalInput: 4,
    component: DesignUnderLine,
    isAllowSubmit: true,
    exerciseKey: "img/FriendsPlus/Page8/E4/Key/answerKeyP8E4.jpg",
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page8/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page8/E4/2.jpg" },
        { url: "img/FriendsPlus/Page8/E4/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E4/4.jpg", input: 2 },
        { url: "img/FriendsPlus/Page8/E4/5.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E4/6.jpg", input: 4 },
        { url: "img/FriendsPlus/Page8/E4/7.jpg", input: 5 },
        { url: "img/FriendsPlus/Page8/E4/8.jpg", input: 6 },
        { url: "img/FriendsPlus/Page8/E4/9.jpg", input: 7 },
        { url: "img/FriendsPlus/Page8/E4/10.jpg", input: 8 },
        { url: "img/FriendsPlus/Page8/E4/11.jpg", input: 9 },
        { url: "img/FriendsPlus/Page8/E4/12.jpg", input: 10 },
        { url: "img/FriendsPlus/Page8/E4/13.jpg", input: 11, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E4/14.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page8/E4/15.jpg" },
        { url: "img/FriendsPlus/Page8/E4/16.jpg", input: 12 },
        { url: "img/FriendsPlus/Page8/E4/17.jpg", input: 13 },
        { url: "img/FriendsPlus/Page8/E4/18.jpg", input: 14 },
        { url: "img/FriendsPlus/Page8/E4/19.jpg", input: 15 },
        { url: "img/FriendsPlus/Page8/E4/20.jpg", input: 16 },
        { url: "img/FriendsPlus/Page8/E4/21.jpg", input: 17, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E4/22.jpg", input: 18 },
        { url: "img/FriendsPlus/Page8/E4/23.jpg", input: 19 },
        { url: "img/FriendsPlus/Page8/E4/24.jpg", input: 20 },
        { url: "img/FriendsPlus/Page8/E4/25.jpg", input: 21 },
        { url: "img/FriendsPlus/Page8/E4/26.jpg", input: 22 },
        { url: "img/FriendsPlus/Page8/E4/27.jpg", input: 23 },
        { url: "img/FriendsPlus/Page8/E4/28.jpg", input: 24 },
        { url: "img/FriendsPlus/Page8/E4/29.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E4/30.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Starter",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page8/E5/1.jpg" }],
    ],
  },
};

export default json;
