import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import LT2 from "../../components/ExcerciseTypes/LineTo/LT2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P11-E1",
    audio: "img/FriendsPlus/Page11/E1/Audio/audio.e1.p11.mp3",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page11/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page11/E1/Audio/tieude.e1.p11.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page11/E1/4.jpg",
          audioUrl: "img/FriendsPlus/Page11/E1/Audio/anh1.e1.p11.mp3",
        },
        {
          url: "img/FriendsPlus/Page11/E1/5.jpg",
          audioUrl: "img/FriendsPlus/Page11/E1/Audio/anh2.e1.p11.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P11-E2",
    audio: "img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    songLyric:
      "<p>This is my family, my family and me. </p>" +
      "<p>This is my family, who can you see? </p>" +
      "<p>Is this your mom? </p>" +
      "<p>Yes, it is. Yes, it is. </p>" +
      "<p>Is this your dad? </p>" +
      "<p>No, it isn't. No, it isn't. </p>" +
      "<p>This is my brother, my brother and me. </p>" +
      "<p>This is my family, my family and me. </p>" +
      "<p>This is my family, who can you see? </p>" +
      "<p>Is this your sister? </p>" +
      "<p>Yes, it is. Yes, it is. </p>" +
      "<p>Is this your grandpa? </p>" +
      "<p>No, it isn't. No, it isn't. </p>" +
      "<p>This is my grandma, my grandma and me. </p>" +
      "<p>This is my family, my family and me. </p>" +
      "<p>This is my family, they all love me!</p>",
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page11/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page11/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page11/E2/Audio/tieude.e2.p11.mp3",
        },
        { url: "img/FriendsPlus/Page11/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page11/E2/4.jpg",
          audioUrl: "img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P11-E3",
    // audio: 'img/FriendsPlus/Page11/E3/Audio/sing.e2.p11.mp3',
    video: "",
    component: LT2,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/answerKeyP11E3.jpg",
    recorder: true,
    toAnchor: "50% -1%",
    fromAnchor: "50% 100%",
    // titleImage: 'img/FriendsPlus/Page11/E3/1.jpg',
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page11/E3/5_01.jpg" },
      ],
      [
        // Column2
        {
          url: "img/FriendsPlus/Page11/E3/5_02-black.jpg",
          isMatching: true,
          id: 1,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_03-black.jpg",
          isMatching: true,
          id: 2,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_04-black.jpg",
          isMatching: true,
          id: 3,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_05-black.jpg",
          isMatching: true,
          id: 4,
        },
      ],
      [
        // Column0
        { url: "img/FriendsPlus/Page11/E3/123.jpg" },
      ],
      [
        // Column0
        { url: "img/FriendsPlus/Page11/E3/123.jpg" },
      ],
      [
        // Column4
        {
          url: "img/FriendsPlus/Page11/E3/5_06-black.jpg",
          isMatching: true,
          id: 5,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_07-black.jpg",
          isMatching: true,
          id: 6,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_08-black.jpg",
          isMatching: true,
          id: 7,
        },
        {
          url: "img/FriendsPlus/Page11/E3/5_09-black.jpg",
          isMatching: true,
          id: 8,
        },
      ],
    ],
    answers: ["1-7", "2-8", "3-5", "4-6"],
  },

  // 3: { // Exercise num
  //   unit : 'Unit 1',
  //   id : 'SB2-U1-P11-E3',
  //   audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
  //   video: '',
  //   component: LT3,
  //   exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKeyP11E3.jpg',
  //   recorder: true,
  //   titleImage: 'img/FriendsPlus/Page11/E3/5_01.jpg',
  //   questionImage: {
  //     answer: [
  //       {
  //         right: 1,
  //         left: 3,
  //       },
  //       {
  //         right: 2,
  //         left: 4,
  //       },
  //       {
  //         right: 3,
  //         left: 1,
  //       },
  //       {
  //         right: 4,
  //         left: 2,
  //       },
  //     ],
  //     ITEMSRIGHT: [
  //       {
  //         id: 1,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_02-black.jpg",
  //       },
  //       {
  //         id: 2,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_03-black.jpg",
  //       },
  //       {
  //         id: 3,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_04-black.jpg",
  //       },
  //       {
  //         id: 4,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_05-black.jpg",
  //       },
  //     ],
  //     ITEMSLEFT: [
  //       {
  //         id: 1,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_06-black.jpg",
  //       },
  //       {
  //         id: 2,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_07-black.jpg",
  //       },
  //       {
  //         id: 3,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_08-black.jpg",
  //       },
  //       {
  //         id: 4,
  //         imgUrl: "img/FriendsPlus/Page11/E3/5_09-black.jpg",
  //       },
  //     ]
  //   }
  // },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB2-U1-P11-E4",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page11/E4/1.jpg" }],
    ],
  },
};

export default json;
