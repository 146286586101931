import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P24-E1',
    audio: 'img/FriendsPlus/Page24/Audio/audio.e1.p24.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page24/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/tieude.e1.p24.mp3' },
        { url: 'img/FriendsPlus/Page24/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page24/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/towel (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page24/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/turtle (mp3cut.net).mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/U.mp3' },
        { url: 'img/FriendsPlus/Page24/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/umbrella (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page24/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/up (mp3cut.net).mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P24-E2',
    audio: 'img/FriendsPlus/Page24/Audio/audio.e2.p24.mp3',
    video: '',
    component: UnderLine,
    songLyric:
      "<p>Look at the turtle. </p>" +
      "<p>It has a towel. </p>" +
      "<p>/t/ /t/ /t/ /t/ /t/ /t/ </p>" +
      "<p>Look at the umbrella. </p>" +
      "<p>It goes up, up, up. </p>" +
      "<p>/ʌ/ / ʌ / / ʌ / / ʌ / / ʌ / / ʌ / </p>" +
      "<p>[repeat]</p>",
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page24/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page24/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/tieude.e2.p24.mp3' },
        { url: 'img/FriendsPlus/Page24/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page24/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/towel (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page24/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/turtle (mp3cut.net).mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/U.mp3' },
        { url: 'img/FriendsPlus/Page24/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/umbrella (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page24/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/up (mp3cut.net).mp3' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P24-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page24/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/6.jpg', input: 1, audioUrl: 'img/FriendsPlus/Page24/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page24/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/8.jpg', input: 2, audioUrl: 'img/FriendsPlus/Page24/Audio/U.mp3' },
        { url: 'img/FriendsPlus/Page24/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/10.jpg', input: 3, audioUrl: 'img/FriendsPlus/Page24/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page24/E3/11.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P24-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKeyP24E4.jpg',
    totalInput: 12,
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page24/E4/3.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page24/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page24/E4/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page24/E4/8.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page24/E4/9.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page24/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/11.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page24/E4/12.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/14.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/15.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page24/E4/16.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page24/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/18.jpg', input: 10, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/19.jpg', input: 11, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/20.jpg', input: 12, },
        { url: 'img/FriendsPlus/Page24/E4/21.jpg', input: 13, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/22.jpg', input: 14, },
        { url: 'img/FriendsPlus/Page24/E4/23.jpg', input: 15 },
        { url: 'img/FriendsPlus/Page24/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/25.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page24/E4/26.jpg', input: 17, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/27.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/28.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page24/E4/29.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page24/E4/30.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page24/E4/31.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/32.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page24/E4/33.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/34.jpg', input: 22, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/35.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page24/E4/36.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page24/E4/37.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page24/E4/38.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page24/E4/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/40.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/41.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/42.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page24/E4/43.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page24/E4/44.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page24/E4/45.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page24/E4/46.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/47.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page24/E4/48.jpg', input: 32, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/49.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/50.jpg', input: 33, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/51.jpg', input: 34 },
        { url: 'img/FriendsPlus/Page24/E4/52.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page24/E4/53.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/54.jpg', input: 37, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/55.jpg', input: 38 },
        { url: 'img/FriendsPlus/Page24/E4/56.jpg', input: 39 },
        { url: 'img/FriendsPlus/Page24/E4/57.jpg', input: 40 },
        { url: 'img/FriendsPlus/Page24/E4/58.jpg', input: 41 },
        { url: 'img/FriendsPlus/Page24/E4/59.jpg', input: 42 },
        { url: 'img/FriendsPlus/Page24/E4/60.jpg', input: 43 },
        { url: 'img/FriendsPlus/Page24/E4/61.jpg', input: 44 },
        { url: 'img/FriendsPlus/Page24/E4/62.jpg', },
        { url: 'img/FriendsPlus/Page24/E4/63.jpg', input: 45 },
        { url: 'img/FriendsPlus/Page24/E4/64.jpg', input: 46, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/65.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/66.jpg', input: 47 },
        { url: 'img/FriendsPlus/Page24/E4/67.jpg', input: 48 },
        { url: 'img/FriendsPlus/Page24/E4/68.jpg', input: 49 },
        { url: 'img/FriendsPlus/Page24/E4/69.jpg', input: 50 },
        { url: 'img/FriendsPlus/Page24/E4/70.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/71.jpg', input: 51, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E4/72.jpg', input: 52 },
        { url: 'img/FriendsPlus/Page24/E4/73.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/74.jpg' }
      ],
    ],
  },
}


export default json;