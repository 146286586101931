import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P9-E1',
    audio: 'img/FriendsPlus/Page9/Audio/audio.e1.p9.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page9/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page9/Audio/tieude.e1.p9.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t2.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t3.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t4.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t5.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t6.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/21.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t7.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/24.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/cn.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/26.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P9-E1',
    audio: 'img/FriendsPlus/Page9/Audio/audio.e2.p9.mp3',
    video: '',
    component: UnderLine,
    songLyric:
      "<p>Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday </p>" +
      "<p>Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday </p>" +
      "<p>Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday </p>",
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page9/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/2.jpg' ,audioUrl: 'img/FriendsPlus/Page9/Audio/tieude.e2.p9.mp3'},
        { url: 'img/FriendsPlus/Page9/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t2.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t3.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t4.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t5.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t6.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/21.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/t7.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/24.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/cn.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E1/26.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P9-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page9/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/tieude.e3.p9.mp3' },
        { url: 'img/FriendsPlus/Page9/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page9/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/anh1.e3.p9.mp3' },
        { url: 'img/FriendsPlus/Page9/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/anh2.e3.p9.mp3' },
        { url: 'img/FriendsPlus/Page9/E3/7.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit : 'Starter',
    id : 'SB2-S-P9-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page9/E4/1.jpg' },
      ],
    ],
  },
}

export default json;