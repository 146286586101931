export const dataBook = {
  1: [
    [
      { imgUrl: 'img/FriendsPlus/Page1/1.jpg', excerciseNum: 0 },
    ],
  ],
  2: [
    [
      { imgUrl: 'img/FriendsPlus/Page2/1.jpg', excerciseNum: 0 },
    ],
  ],
  3: [
    [
      { imgUrl: 'img/FriendsPlus/Page3/1.jpg', excerciseNum: 0 },
    ],
  ],
  4: [
    [
      { imgUrl: 'img/FriendsPlus/Page4/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page4/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/4.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/6.jpg', excerciseNum: 4 },
    ],
  ],
  5: [
    [
      { imgUrl: 'img/FriendsPlus/Page5/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/4.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page5/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/6.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page5/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/8.jpg', excerciseNum: 0 },
    ],
  ],
  6: [
    [
      { imgUrl: 'img/FriendsPlus/Page6/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page6/6.jpg', excerciseNum: 5 },
    ],
  ],
  7: [
    [
      { imgUrl: 'img/FriendsPlus/Page7/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/4.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page7/5.jpg', excerciseNum: 4 },
    ],
  ],
  8: [
    [
      { imgUrl: 'img/FriendsPlus/Page8/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/2.1.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page8/6.jpg', excerciseNum: 5 },
    ],
  ],
  9: [
    [
      { imgUrl: 'img/FriendsPlus/Page9/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/5.jpg', excerciseNum: 4 },
    ],
  ],
  10: [
    [
      { imgUrl: 'img/FriendsPlus/Page10/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/4.jpg', excerciseNum: 3 },
    ],
  ],
  11: [
    [
      { imgUrl: 'img/FriendsPlus/Page11/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/4.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page11/5.jpg', excerciseNum: 4},
    ],
  ],
  12: [
    [
      { imgUrl: 'img/FriendsPlus/Page12/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/5.jpg', excerciseNum: 4 },
    ],
  ],
  13: [
    [
      { imgUrl: 'img/FriendsPlus/Page13/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/5.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page13/6.jpg', excerciseNum: 4 },
    ],
  ],
  14: [
    [
      { imgUrl: 'img/FriendsPlus/Page14/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/5.jpg', excerciseNum: 4 },
    ],
  ],
  15: [
    [
      { imgUrl: 'img/FriendsPlus/Page15/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/4.jpg', excerciseNum: 3 },
    ],
  ],
  16: [
    [
      { imgUrl: 'img/FriendsPlus/Page16/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/4.jpg', excerciseNum: 3 },
    ],
  ],
  17: [
    [
      { imgUrl: 'img/FriendsPlus/Page17/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page17/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page17/7.jpg', excerciseNum: 4 },
    ],
  ],
  18: [
    [
      { imgUrl: 'img/FriendsPlus/Page18/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/5.jpg', excerciseNum: 4 },
    ],
  ],
  19: [
    [
      { imgUrl: 'img/FriendsPlus/Page19/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/4.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page19/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page19/7.jpg', excerciseNum: 4 },
    ],
  ],
  20: [
    [
      { imgUrl: 'img/FriendsPlus/Page20/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/5.jpg', excerciseNum: 4 },
    ],
  ],
  21: [
    [
      { imgUrl: 'img/FriendsPlus/Page21/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page21/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page21/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page21/7.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/8.jpg', excerciseNum: 0 }, 
    ],
   
  ],
  22: [
    [
      { imgUrl: 'img/FriendsPlus/Page22/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/4.jpg', excerciseNum: 3 },
    ],
   
  ],
  23: [
    [
      { imgUrl: 'img/FriendsPlus/Page23/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page23/5.jpg', excerciseNum: 4 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page23/7.jpg', excerciseNum: 4 },
      
    ],
  
  ],
  24: [
    [
      { imgUrl: 'img/FriendsPlus/Page24/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/3.jpg', excerciseNum: 2 },
      
    ],
    [
     
      { imgUrl: 'img/FriendsPlus/Page24/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/5.jpg', excerciseNum: 4 },
    ],
 
  ],
  25: [
    [
      { imgUrl: 'img/FriendsPlus/Page25/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/2.jpg', excerciseNum: 1 },
     
    ],
    [
     
      { imgUrl: 'img/FriendsPlus/Page25/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/5.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page25/6.jpg', excerciseNum: 4 },
    ],
  ],
  26: [
    [
      { imgUrl: 'img/FriendsPlus/Page26/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/2.jpg', excerciseNum: 1 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/3.jpg', excerciseNum: 2 },
     
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/5.jpg', excerciseNum: 4 },
    ],
  ],
  27: [
    [
      { imgUrl: 'img/FriendsPlus/Page27/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page27/3.jpg', excerciseNum: 0},
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page27/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page27/7.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/8.jpg', excerciseNum: 0 },     
    ],
  ],
  28: [
    [
      { imgUrl: 'img/FriendsPlus/Page28/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/3.jpg', excerciseNum: 2 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/4.jpg', excerciseNum: 3 },
      
    ],
  
  ],
  29: [
    [
      { imgUrl: 'img/FriendsPlus/Page29/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/2.jpg', excerciseNum: 1 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/3.jpg', excerciseNum: 2 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/4.jpg', excerciseNum: 3 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/5.jpg', excerciseNum: 4 },
    ],
  ],
  30: [
    [
      { imgUrl: 'img/FriendsPlus/Page30/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/4.jpg', excerciseNum: 3 },
      
    ],
  
  ],
  31: [
    [
      { imgUrl: 'img/FriendsPlus/Page31/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/2.jpg', excerciseNum: 1 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/3.jpg', excerciseNum: 2 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page31/5.jpg', excerciseNum: 4 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page31/7.jpg', excerciseNum: 4 },
      
    ],
  ],
  32: [
    [
      { imgUrl: 'img/FriendsPlus/Page32/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/5.jpg', excerciseNum: 4 },
    ],
  ],
  33: [
    [
      { imgUrl: 'img/FriendsPlus/Page33/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/5.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page33/6.jpg', excerciseNum: 4 },
    ],
  ],
  34: [
    [
      { imgUrl: 'img/FriendsPlus/Page34/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/2.jpg', excerciseNum: 1 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/4.jpg', excerciseNum: 3 },
     
    ],
  ],
  35: [
    [
      { imgUrl: 'img/FriendsPlus/Page35/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page35/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page35/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page35/7.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/8.jpg', excerciseNum: 0 },
     
    ],
  ],
  36: [
    [
      { imgUrl: 'img/FriendsPlus/Page36/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/3.jpg', excerciseNum: 2 }, 
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/4.jpg', excerciseNum: 3 }, 
    ],

  ],
  37: [
    [
      { imgUrl: 'img/FriendsPlus/Page37/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/2.jpg', excerciseNum: 1 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page37/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page37/7.jpg', excerciseNum: 4 },
    ],

  ],
  38: [
    [
      { imgUrl: 'img/FriendsPlus/Page38/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/2.jpg', excerciseNum: 1 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/3.jpg', excerciseNum: 2 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/4.jpg', excerciseNum: 3 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page38/6.jpg', excerciseNum: 5 },
    ],
   
  ],
  39: [
    [
      { imgUrl: 'img/FriendsPlus/Page39/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/2.jpg', excerciseNum: 1 },
    

    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/3.jpg', excerciseNum: 2 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/4.jpg', excerciseNum: 3 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/5.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page39/6.jpg', excerciseNum: 4 },
    
    ],

  ],
  40: [
    [
      { imgUrl: 'img/FriendsPlus/Page40/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/4.jpg', excerciseNum: 3 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page40/6.jpg', excerciseNum: 5 },
    ],

  ],
  41: [
    [
      { imgUrl: 'img/FriendsPlus/Page41/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page41/3.jpg', excerciseNum: 0 },

    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page41/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page41/7.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/8.jpg', excerciseNum: 0 },
     
    ],
  

  ],
  42: [
    [
      { imgUrl: 'img/FriendsPlus/Page42/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page42/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/4.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/5.jpg', excerciseNum: 3 },
    ],

  ],
  43: [
    [
      { imgUrl: 'img/FriendsPlus/Page43/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/3.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page43/4.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/5.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page43/6.jpg', excerciseNum: 4 },
    ],
  ],
  44: [
    [
      { imgUrl: 'img/FriendsPlus/Page44/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page44/6.jpg', excerciseNum: 5 },
      
    ],
  ],
  45: [
    [
      { imgUrl: 'img/FriendsPlus/Page45/1.jpg', excerciseNum: 0 },
    ],

    [
      { imgUrl: 'img/FriendsPlus/Page45/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/4.jpg', excerciseNum: 3 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page45/6.jpg', excerciseNum: 5 },
     
    ],
  ],
  46: [
    [
      { imgUrl: 'img/FriendsPlus/Page46/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/2.jpg', excerciseNum: 1 },
    ],

    [
      { imgUrl: 'img/FriendsPlus/Page46/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/4.jpg', excerciseNum: 3 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/5.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page46/6.jpg', excerciseNum: 5 },

    ]
  ],
  47: [
    [
      { imgUrl: 'img/FriendsPlus/Page47/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page47/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page47/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page47/7.jpg', excerciseNum: 0 },
    ],
  
    [
      { imgUrl: 'img/FriendsPlus/Page47/8.jpg', excerciseNum: 0 },

    ]
  ],
  48: [
    [
      { imgUrl: 'img/FriendsPlus/Page48/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/4.jpg', excerciseNum: 3 },

    ]
  ],
  49: [
    [
      { imgUrl: 'img/FriendsPlus/Page49/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/3.jpg', excerciseNum: 2 },
      
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/4.jpg', excerciseNum: 3 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/5.jpg', excerciseNum: 4 },
      
    ],
  ],
  50: [
    [
      { imgUrl: 'img/FriendsPlus/Page50/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/2.jpg', excerciseNum: 1 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/3.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page50/4.jpg', excerciseNum: 3 },
    ],
   

  ],
  51: [
    [
      { imgUrl: 'img/FriendsPlus/Page51/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/2.jpg', excerciseNum: 4 },
    
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/3.jpg', excerciseNum: 5 },
     
    ]
  ],
  52: [
    [
      { imgUrl: 'img/FriendsPlus/Page52/1.jpg', excerciseNum: 0 },
    ],

    [
      { imgUrl: 'img/FriendsPlus/Page52/2.jpg', excerciseNum: 1 }, 
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/4.jpg', excerciseNum: 3 },
    ],
  ],
  53: [
    [
      { imgUrl: 'img/FriendsPlus/Page53/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/2.jpg', excerciseNum: 1 },
     
    ],
    [
     
      { imgUrl: 'img/FriendsPlus/Page53/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/4.jpg', excerciseNum: 3 },
    ],

  ],
  54: [
    [
      { imgUrl: 'img/FriendsPlus/Page54/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/3.jpg', excerciseNum: 2 },   
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/4.jpg', excerciseNum: 3 },
    ],
  ],
  55: [
    [
      { imgUrl: 'img/FriendsPlus/Page55/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/2.jpg', excerciseNum: 1 }, 
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/4.jpg', excerciseNum: 3 },
    ],
  ],
  56: [
    [
      { imgUrl: 'img/FriendsPlus/Page56/1.jpg', excerciseNum: 0 },
    ],

    [
      { imgUrl: 'img/FriendsPlus/Page56/2.jpg', excerciseNum: 1 },

    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/3.jpg', excerciseNum: 2 },
     
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/4.jpg', excerciseNum: 3 },
    ],
  ],
  57: [
    [
      { imgUrl: 'img/FriendsPlus/Page57/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/2.jpg', excerciseNum: 1 },
      
    ],
    [
     
      { imgUrl: 'img/FriendsPlus/Page57/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/4.jpg', excerciseNum: 3 },
    ],
  
  ],
  58: [
    [
      { imgUrl: 'img/FriendsPlus/Page58/1.jpg', excerciseNum: 0 },
    ],
  
  ],
  59: [
    [
      { imgUrl: 'img/FriendsPlus/Page59/1.jpg', excerciseNum: 0 },
    ],
  ],
  60: [
    [
      { imgUrl: 'img/FriendsPlus/Page60/1.jpg', excerciseNum: 0 },
    ],
  ],
  61: [
    [
      { imgUrl: 'img/FriendsPlus/Page61/1.jpg', excerciseNum: 0 },
    ],
  ],
  62: [
    [
      { imgUrl: 'img/FriendsPlus/Page62/1.jpg', excerciseNum: 0 },
    ],
  ],
  63: [
    [
      { imgUrl: 'img/FriendsPlus/Page63/1.jpg', excerciseNum: 0 },
    ],
  ],
  64: [
    [
      { imgUrl: 'img/FriendsPlus/Page64/1.jpg', excerciseNum: 0 },
    ],
  ],
  65: [
    [
      { imgUrl: 'img/FriendsPlus/Page65/1.jpg', excerciseNum: 0 },
    ],
  ],
}