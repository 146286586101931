import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P35-E1",
    audio: "img/FriendsPlus/Page35/E1/Audio/audio.e1.p35.mp3",
    video: "Videos/P35E1.mp4",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page35/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page35/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page35/E1/Audio/tieude.e1.p35.mp3",
        },
        {
          url: "img/FriendsPlus/Page35/E1/3.jpg",
          videoUrl: "Videos/P35E1.mp4",
        },
        { url: "img/FriendsPlus/Page35/E1/4.jpg" },
      ],

      [{ url: "img/FriendsPlus/Page35/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page35/E1/6.jpg" },
        { url: "img/FriendsPlus/Page35/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page35/E1/8.jpg" },
        { url: "img/FriendsPlus/Page35/E1/9.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P35-E2",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page35/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page35/E1/6.jpg" },
        { url: "img/FriendsPlus/Page35/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page35/E1/8.jpg" },
        { url: "img/FriendsPlus/Page35/E1/9.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB2-U4-P35-E3",
    audio: "",
    video: "",
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page35/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page35/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page35/E3/3.jpg",
          audioUrl: "img/FriendsPlus/Page35/E3/Audio/tieude.e3.p35.mp3",
        },
        { url: "img/FriendsPlus/Page35/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page35/E3/5.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page35/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page35/E3/Audio/anh1.e3.p35.mp3",
        },
        {
          url: "img/FriendsPlus/Page35/E1/7.jpg",
          audioUrl: "img/FriendsPlus/Page35/E3/Audio/anh2.e3.p35.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page35/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page35/E3/Audio/anh3.e3.p35.mp3",
        },
        {
          url: "img/FriendsPlus/Page35/E1/9.jpg",
          audioUrl: "img/FriendsPlus/Page35/E3/Audio/anh4.e3.p35.mp3",
        },
      ],
    ],
  },
};

export default json;
