import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P5-E1",
    audio: "",
    video: "",
    component: UnderLine,
    exerciseKey: "",
    isAllowSubmit: true,
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page5/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/2.jpg",
          audioUrl: "img/FriendsPlus/Page5/Audio/tieude.e1.p5.mp3",
        },
        { url: "img/FriendsPlus/Page5/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page5/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/6.jpg",
          audioUrl: "img/FriendsPlus/Page5/Audio/How old are you.mp3",
        },
        { url: "img/FriendsPlus/Page5/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/8.jpg",
          audioUrl: "img/FriendsPlus/Page5/Audio/I like purple.mp3",
        },
        { url: "img/FriendsPlus/Page5/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/10.jpg",
          audioUrl: "img/FriendsPlus/Page5/Audio/I like orange.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P5-E1",
    audio: "img/FriendsPlus/Page5/Audio/sing.e2.p5.mp3",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    songLyric:
      "<p>How old are you? </p>" +
      "<p>I'm seven, </p>" +
      "<p>I'm seven, </p>" +
      "<p>I'm seven, </p>" +
      "<p>What color do you like? </p>" +
      "<p>I like brown. </p>" +
      "<p>My dog is brown. </p>" +
      "<p>My socks are brown. </p>" +
      "<p>I like brown. </p>" +
      "<p>How old are you? </p>" +
      "<p>I'm seven,</p>" +
      "<p>I'm seven,</p>" +
      "<p>I'm seven,</p>" +
      "<p>What color do you like? </p>" +
      "<p>I like pink. </p>" +
      "<p>My bike is pink. </p>" +
      "<p>My shoes are pink. </p>" +
      "<p>I like pink.</p>",
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page5/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page5/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page5/Audio/tieude.e2.p5.mp3",
        },
        { url: "img/FriendsPlus/Page5/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E2/4.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P5-E1",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page5/E3/1.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P5-E1",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page5/E4/1.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Starter",
    id: "SB2-S-P5-E1",
    audio: "",
    video: "",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page5/E5/1.jpg" }],
    ],
  },
};

export default json;
